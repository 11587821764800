import { required, confirmed, email } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
  message: 'Éste campo es requerido.',
});

extend('email', {
  ...email,
  message: 'Debe ingresar un email válido.',
});

extend('confirmed', {
  ...confirmed,
  message: 'La confirmación no coincide.',
});
